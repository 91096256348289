import Database from "./Database"

class WMPromotions {
    getAll() {
        return Database.getAll("promotions")
    }

    create(promotion) {
        return Database.addOne("promotions", promotion).then(response => {
            promotion.uid = response.id
            return promotion
        }).catch(error => {
            console.log("Error creating product promotion", error)
        })
    }

    async processPromotion(promotion) {
        const setStatus = await this.setPromotionStatus(promotion.uid, "Processing")
        
        const products = await Database.getAllWhere("product_listings", "categories", "array-contains-any", promotion.include[0].value)
        console.log(products)
    }

    setPromotionStatus(uid, status) {
        return Database.updateOne("promotions", uid, {status: status})
    }
}

const Promotions = new WMPromotions()
export default Promotions