import Promotions from "@/api/Promotions"

const state = () => ({
    promotions: [],
})

// getters
const getters = {
    getPromotions(state) {
        return state.promotions;
    },
}

// actions
const actions = {
    setPromotions({commit}) {
        return Promotions.getAll().then(response => {
            commit('setPromotions', response)
        })
    },

    createPromotion({commit}, promotion) {
        return Promotions.create(promotion).then((response) => {
            commit('addPromotion', response)
        })
    },

    processPromotion({commit}, promotion) {
        return Promotions.processPromotion(promotion)
    }

}

// mutations
const mutations = {
    setPromotions(state, payload) {
        state.promotions = payload;
    },
    addPromotion(state, payload) {
        state.promotions.push(payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}