import PricingProfiles from "@/api/PricingProfiles"

const state = () => ({
    profiles: [],
    currentProfile: null
})

// getters
const getters = {
    getProfiles(state) {
        return state.profiles;
    },

    getCurrentPricingProfile(state) {
        return state.currentProfile
    },

    getCurrentDiscount(state) {
        return state.currentProfile.discount
    }
}

// actions
const actions = {
    setProfiles({commit}) {
       return PricingProfiles.getAll().then(response => {
           commit("setProfiles", response)
       }).catch(err => {
           console.log("Error setting pricing profiles", err)
       })
    },
    setProfile({commit}, userUID) {
        return PricingProfiles.getUserPricingProfile(userUID).then((response) => {
            console.log("Current User Pricing Profile", response)
            commit("setCurrentProfile", response)
        }).catch(err => {
            console.log("Error getting current pricing profile", err)
        })
    },
    createProfile({commit}, profile) {
        return PricingProfiles.create(profile).then(response => {
            commit("addProfile", response)
        }).catch(err => {
            console.log("Error creating pricing profile", err)
        })
    }
}

// mutations
const mutations = {
    addProfile(state, payload) {
        state.profiles.push(payload);
    },

    setProfiles(state, payload) {
        state.profiles = payload
    },

    setCurrentProfile(state, payload) {
        state.currentProfile = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}