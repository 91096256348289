const state = () => ({
    toasts: [],
})

// getters
const getters = {
    getToasts(state) {
        return state.toasts;
    },
}

// actions
const actions = {
    addToast({commit}, toast) {
       commit('addToast', toast)
    },

    removeToast({commit}, toastID) {
        commit('removeToast', toastID)
    }

}

// mutations
const mutations = {
    addToast(state, payload) {
        state.toasts.push(payload);
    },

    removeToast(state, payload) {
        var index 
        for(var i = 0; i < state.toasts.length; i++) {
            if(state.toasts[i].id == payload) {
                state.toasts.splice(i, 1)
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}