
class WMArrayHelpers {
    chunk(array, perChunk) {
        return array.reduce((all,one,i) => {
            const ch = Math.floor(i/perChunk); 
            all[ch] = [].concat((all[ch]||[]),one); 
            return all
         }, [])
    }
}

const ArrayHelpers = new WMArrayHelpers()
export default ArrayHelpers