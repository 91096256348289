
class WMPermissions {
    hasPermission(...permissions) {

        for(var i = 0; i < permissions.length; i++) {
            var reqPerm = permissions[i]
            var hasPerm = this.checkForPermission(reqPerm)
            
            if(hasPerm) {
                return true
            }
        }
    }

    checkForPermission(permission) {
        var granted = this.profile.permissions

        if(Array.isArray(permission)) {
            permission = permission[0]
        }

        for(var j = 0; j < granted.length; j++) {
            var grantedPerm = granted[j]
            if(grantedPerm.includes(permission) || grantedPerm == "*") {
                return true
            }
        }

        return false
    }

    setProfile(profile) {
        this.profile = profile
    }
}

const Permissions = new WMPermissions()
export default Permissions