import Products from "@/api/ProductListings"

const state = () => ({
    listings: null,
})

// getters
const getters = {
    getListings(state) {
        return state.listings
    },
}

// actions
const actions = {
    setListings({commit}, products) {

    },
    setListingsByCategory({commit}, data) {
        Products.getByCategory(data).then((response) => {
            if(response.success && response.success == false) {
                console.log("ERROR GETTING PRODUCTS", response)

                commit('setListings', [])

            } else {
                console.log("RESPONSE", response)
                commit('setListings', response)
            }
        }).catch((error) => {
            console.log("ERROR GETTING PRODUCTS", error)
            commit('setListings', [])
        })
    },
    nextPage({commit,state}, data) {
        data.startAfter = state.listings.at(-1).id
        Products.getNextPageofCategory(data).then((response) => {
            commit('setListings', response)
        }).catch((error) => {
            console.log("ERROR GETTING NEXT PAGE", error)
        })
    },
    previousPage({commit,state}, data) {
        data.endBefore = state.listings[0].id
        Products.getPreviousPageofCategory(data).then((response) => {
            console.log("PRODUCTS", response)
            commit('setListings', response)
        }).catch((error) => {
            console.log("ERROR GETTING NEXT PAGE", error)
        })
    }

}

// mutations
const mutations = {
    setListings(state, payload) {
        state.listings = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}