import BugReports from "@/api/BugReports"

const state = () => ({
    bugs: [],
})

// getters
const getters = {
    getBugs(state) {
        return state.bugs;
    },
}

// actions
const actions = {
    setBugs({commit}) {
       return BugReports.getAll().then(response => {
           commit('setBugs', response)
       }).catch(err => {
           console.log("Error getting bug reports", err)
       })
    },
}

// mutations
const mutations = {
    setBugs(state, payload) {
        state.bugs = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}