import Database from "./Database"
import FB from "./FB"
class WMWebsiteOrders {

    initialize() {
        this._updateStatus = FB.getServerFunction('orders-updateStatus')
    }

    getAll(options = {}) {
        return Database.getAll("orders", options).then((querySnapshot) => {
            return querySnapshot
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getOne(uid) {
        return Database.getOne("orders", uid)
    }

    async updateStatus(id, status) {
        const updateRes = await this._updateStatus({id: id, status: status}).then(res=>{return true}).catch(err=>{console.log("Error updating order status", err); return false;})

        if(updateRes) {
            await Database.updateOne("orders", id + "", {status: status})
        }

        return updateRes
    }
}

const WebsiteOrders = new WMWebsiteOrders()
export default WebsiteOrders