import Products from "@/api/Products"

const state = () => ({
    products: null,
})

// getters
const getters = {
    getProducts(state) {
        return state.products
    },
}

// actions
const actions = {
    setProducts({commit}) {
       return Products.get().then(res => {
           commit("setProducts", res)
       })
    },
    createProduct({commit}, product) {
        return Products.create(product).then(res => {
            commit("addProduct", res)
            return res
        })
    },
    updateProduct({commit}, product) {
        console.log("UPDATING", product)
        return Products.update(product).then(res => {
            commit("updateProduct", res)
            return res
        })
    },
    deleteProduct({commit}, uid) {
        return Products.delete(uid).then(res => {
            commit("deleteProduct", uid)
            return true
        })
    }
}

// mutations
const mutations = {
    setProducts(state, payload) {
        state.products = payload
    },
    addProduct(state, payload) {
        if(state.products) {
            state.products.push(payload)
        }
    },
    deleteProduct(state, payload) {
        if(payload && state.products) {
            for(var i = 0; i < state.products.length; i++) {
                if(state.products[i].uid == payload) {
                    state.products.splice(i, 1)
                }
            }
        }
    },
    updateProduct(state, payload) {
        if(payload.uid && state.products) {
            for(var i = 0; i < state.products.length; i++) {
                if(state.products[i].uid == payload.uid) {
                    state.products[i] = payload
                }
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}