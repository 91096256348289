import Users from "@/api/Users";
import User from "@/api/User"

const state = () => ({
    users: null,
})

// getters
const getters = {
    getUsers(state) {
        return state.users;
    },
}

// actions
const actions = {
    setUsers({commit}, user) {
        Users.getAll().then((users) => {
            commit('setUsers', users)
        })
    },
    createUser({commit}, user) {
        console.log("STORE")
        return Users.createUser(user).then(response => {
            var usr = {}
            Object.keys(user).forEach(key => {
                if(key != "password") {
                    usr[key] = user[key]
                }
            })

            commit('addUser', usr)
        }).catch(error => {
            console.log("Error creating new user", error)
        })
    }
}

// mutations
const mutations = {
    setUsers(state, payload) {
        state.users = payload;
    },
    addUser(state, payload) {
        if(state.users) {
            state.users.push(payload)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}