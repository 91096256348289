import WebsiteOrders from "@/api/WebsiteOrders"

const state = () => ({
    orders: null,
})

// getters
const getters = {
    getOrders(state) {
        return state.orders
    },
}

// actions
const actions = {
    setOrders({commit}) {
        WebsiteOrders.getAll({limit: 500}).then((response) => {
            commit("setOrders", response)
        })
    },
}

// mutations
const mutations = {
    setOrders(state, payload) {
        state.orders = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}