import Webhooks from "@/api/Webhooks"

const state = () => ({
    webhooks: null,
})

// getters
const getters = {
    getWebhooks(state) {
        return state.webhooks;
    },
}

// actions
const actions = {
    setWebhooks({commit}) {
        Webhooks.getAll().then((webhooks) => {
            console.log("WEBHOOKS", webhooks)
            commit('setWebhooks', webhooks)
        })
    },

}

// mutations
const mutations = {
    setWebhooks(state, payload) {
        state.webhooks = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}