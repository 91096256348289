import Database from "./Database"

class WMProductListingCategories {
    getProductCategories() {
        return Database.getAll("product_listing_categories")        
    }

    getProductCategory(id) {
        id = Number(id)
        return Database.getOneWhere("product_listing_categories", "id", "==", id)
    }
}

const ProductListingCategories = new WMProductListingCategories()
export default ProductListingCategories