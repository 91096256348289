import FB from "./FB"

class WMDatabases {

    initialize() {
        this._syncDatabase = FB.getServerFunction('databases-syncDatabase')
    }

    syncDatabase(slug) {
        return this._syncDatabase({slug: slug})
    }

}

const Databases = new WMDatabases()
export default Databases