import firebase from "firebase"

class WMFB {
    initialize() {
        this.database = firebase.firestore()
        this.firebase = firebase
    }

    getServerFunction(name) {
        return firebase.functions().httpsCallable(name)
    }
}

const FB = new WMFB()
export default FB