
class WMStringHelpers {
    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          )
    }
}

const StringHelpers = new WMStringHelpers()
export default StringHelpers