import ProductUpdates from "@/api/ProductUpdates"

const state = () => ({
    updates: null,
})

// getters
const getters = {
    getUpdates(state) {
        return state.updates
    },
}

// actions
const actions = {
     setUpdates({commit}) {
        ProductUpdates.getAll().then((response) => {
            commit('setUpdates', response)
        })
   
    },
}

// mutations
const mutations = {
    setUpdates(state, payload) {
        state.updates = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}