import Database from "./Database"

class WMMailingList {
    getContacts() {
        return Database.getAll("mailing_addresses").then((response) => {
            return response
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    addContact(contact) {
        return Database.addOne("mailing_addresses", contact)
    }

    deleteContact(contact) {
        return Database.deleteOne("mailing_addresses", contact.uid)
    }
}

const MailingList = new WMMailingList()
export default MailingList