import FB from "./FB"
import Database from "./Database"


class WMProductLists {
    getAll() {
        var userUID = FB.firebase.auth().currentUser.uid
        return Database.getAllWhere("product_lists", "ownerUID", "==", userUID).then((response) => {
            return response
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    addToList(uid, products) {
        var _products = []

        products.forEach(product => {
            _products.push(product.uid)
        })

        console.log("PUSHING", _products)

        return FB.database.collection("product_lists").doc(uid).update({
            products: FB.firebase.firestore.FieldValue.arrayUnion(..._products)
        }).then(response => {
            return {
                success: true
            }
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }
}

const ProductLists = new WMProductLists()
export default ProductLists