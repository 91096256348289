import ProductSuppliers from "@/api/ProductSuppliers"

const state = () => ({
    suppliers: null
})

// getters
const getters = {
    getProductSuppliers(state) {
        return state.suppliers;
    },
}

// actions
const actions = {
    setProductSuppliers({commit}) {
        return ProductSuppliers.getAll().then(response => {
            commit('setProductSuppliers', response)
        })
    },

    createProductSupplier({commit}, data) {
        return ProductSuppliers.createProductSupplier(data).then(response => {
            commit("addProductSupplier", response)
            return response
        }).catch(error => {
            console.log("Error adding product supplier", error)
        })
    }
}

// mutations
const mutations = {
    setProductSuppliers(state, payload) {
        state.suppliers = payload;
    },
    addProductSupplier(state, payload) {
        state.suppliers.push(payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}