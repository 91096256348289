<template>
  <div class="TopNavSearch">
    <form v-on:submit.prevent="search">
      <div class="field">
        <p class="control has-icons-left">
          <input class="input" type="text" v-model="query" placeholder="Search">
          <span class="icon is-small is-left">
            <i class="fas fa-search"></i>
          </span>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'TopNavSearch',
  data() {
    return {
      query: ""
    }
  },
  methods: {
    search() {
      this.$router.push("/dashboard/search?q=" + this.query)
      this.query = ""
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .TopNavSearch {
    width: 100%;
  }
</style>
