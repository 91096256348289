import FB from "./FB"
import Databases from "./Databases"
import ProductListings from "./ProductListings"
import Webhooks from "./Webhooks"
import Users from "./Users"
import WebsiteOrders from "./WebsiteOrders"

class WMAPI {
    initialize() {
        FB.initialize()
        Databases.initialize()
        ProductListings.initialize()
        Webhooks.initialize()
        Users.initialize()
        WebsiteOrders.initialize()
    }
}

const API = new WMAPI()
export default API