<template>
  <div class="toast">
    <div :class="'notification is-success ' +  (active? 'active' : '')">
      {{message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: String,
    active: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .notification {
    box-shadow: 0 8px 8px -4px rgba(0, 0, 0, .25);

  }



</style>
