import array from "./helpers/array"
import string from "./helpers/string"
class WMHelpers {
    constructor() {
        this.array = array
        this.string = string
    }
}

const Helpers = new WMHelpers()
export default Helpers