<template>
  <div class="TopNavigationBar has-background-link" v-if="getProfile">
    
    <div class="columns">
      <div class="column is-one-third top-left-controls">
        <div class="">
          <i class="fas fa-bars" ></i>
        </div>
        
        <div class="brand">
          <router-link to="/dashboard"><img src="@/assets/logo.png" style="filter: invert(100%) saturate(0%)"/></router-link>
        </div>
      </div>
      <div class="column is-one-third search">
        <Search></Search>
      </div>
      <div class="column is-one-third controls">
        <div class="quickActions">
          <button class="button" @click="reportProblemModalOpen = true"><i class="fas fa-bug"></i></button>
        </div>
        <div class="profileDropdown">
          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu6">
                <span>{{getProfile.first_name}} {{getProfile.last_name.substr(0,1)}}.</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu6" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item">
                  <a href="#" @click="signOut">Sign Out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportProblemModal @close="reportProblemModalOpen = false" :open="reportProblemModalOpen"></ReportProblemModal>
    </div>
  </div>
</template>

<script>
import ReportProblemModal from "@/components/global/ReportProblemModal"
import Search from "@/components/global/topnav/Search"
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('User')

export default {
  name: 'TopNavigationBar',
  components: {
    Search,
    ReportProblemModal
  },
  data() {
    return {
      reportProblemModalOpen: false
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("User/signOut")
    }
  },
  computed: {
    ...mapGetters(['getProfile'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .quickActions {
    margin-right: 5px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .top-left-controls {
    display: flex;
    align-items: center;
  }

  .top-left-controls * {
    color: white;
    margin-right: 5px;
  }

  .profileDropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .brand {
    display: flex;
    align-items: center;
  }

  .TopNavigationBar {
    width: 100%;
    padding: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
