import Database from "./Database";

class WMProductSuppliers {
    getAll() {
        return Database.getAll("product_suppliers")
    }

    createProductSupplier(data) {
        var {name} = data
        return Database.addOne("product_suppliers", {
            name: name
        }).then(response => {
            data.uid = response.id
            return data
        })
    }
}

const ProductSuppliers = new WMProductSuppliers()
export default ProductSuppliers