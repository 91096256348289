import firebase from "firebase"
import API from "@/api/";

const firebaseConfig = {
  apiKey: "AIzaSyB4Z22yaARrkL_l1t7zSqb8JfDudMADoog",
  authDomain: "webmanager-ea6a8.firebaseapp.com",
  projectId: "webmanager-ea6a8",
  storageBucket: "webmanager-ea6a8.appspot.com",
  messagingSenderId: "465773339619",
  appId: "1:465773339619:web:957805b47c8f9a437f36d6",
  measurementId: "G-BP2BH6LHLQ"
};

firebase.initializeApp(firebaseConfig);

API.initialize()

if(process.env.NODE_ENV == 'development' && location.hostname === "localhost") {
  console.log("DEVELOPMENT: Using Firebase emulators...")
  firebase.functions().useEmulator("localhost", 5001)
  //firebase.firestore().useEmulator("localhost", 5002);

} else {
  const analytics = firebase.analytics();

}


