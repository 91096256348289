import FB from "./FB"
import Database from "./Database"
import Helpers from "./Helpers"

class WMProducts {
    initialize() {
        this._getAllFromWooCommerce = FB.getServerFunction('products-getAllFromWooCommerce')
        this._getVariations = FB.getServerFunction('products-getVariations')
        this._getVariation = FB.getServerFunction('products-getVariation')
        this._updateProducts = FB.getServerFunction('products-updateProducts')
        this._updateProduct = FB.getServerFunction('products-updateProduct')

        this._updateVariation = FB.getServerFunction('products-updateVariation')
        this._updateProductWithVariations = FB.getServerFunction('products-updateProductWithVariations')

        this._getWebsiteListing = FB.getServerFunction('products-getWebsiteListing')
        this._updateCategories = FB.getServerFunction('products-updateCategories')

        this._deleteVariations = FB.getServerFunction('products-deleteVariations')
        this._createVariations = FB.getServerFunction('products-createVariations')


        this.DATABASE_PRODUCTLISTINGS = "product_listings"
    }

    getUID(id) {
        id = Number(id)
        return Database.getOneWhere("product_listings", "id", "==", id).then((response) => {
            if(response) {
                return response.uid
            } else {
                return {
                    success: false,
                    message: "No results"
                }
            }
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getWebsiteListing(id) {
        return this._getWebsiteListing({id: id}).then((response) => {
            return response.data
        }).catch(error => {
            console.log("Error getting website listing for " + id, error)
        })
    }

    getByID(id) {
        return Database.getOne("product_listings", id+"")
    }

    updateProduct(update) {
        return this._updateProduct(update).then(response => {
            return response.data
        }).catch(error => {
            return {
                success: false,
                message: JSON.stringify(error)
            }
        })
    }

    updateProductWithVariations(parentProduct, variations) {
        var data = {
            parent: parentProduct,
            variations: variations
        }

        return this._updateProductWithVariations(data).then(response => {
            return response.data
        }).catch(error => {
            return {
                success: false,
                message: JSON.stringify(error)
            }
        })
    }

    updateProducts(updates) {
        return this._updateProducts({updates: updates}).then(response => {
            return response.data
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    deleteVariations(uid, id, variations) {
        var operations = Helpers.array.chunk(variations, 100)
        var promises = []

        operations.forEach(operation => {
            promises.push(this._deleteVariations({
                uid: uid,
                id: id,
                variations: operation
            }))
        })
        return Promise.all(promises)
    }

    createVariations(uid, id, variations) {
        console.log("Received variations", variations)
        var operations = Helpers.array.chunk(variations, 100)
        var promises = []



        operations.forEach(operation => {
            promises.push(this._createVariations({
                uid: uid,
                id: id,
                variations: operation
            }))
        })

        console.log("OPERATIONS", operations)
        console.log("PROMISES", promises)
        return Promise.all(promises)
    }

    updateVariation(update) {
        return this._updateVariation({update: update}).then(response => {
            return response.data
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getVariations(id) {
        return this._getVariations({id: id}).then(response => {
            return response.data
        })
    }

    async getVariation(id) {
        console.log("Getting " + id)
        const data = await Database.getOne("product_listings", id + "")
        
        if(!data) {
           // return await this._getVariation({id: id})
        }

        return data
    }

    updateCategories(uid, id, categories) {
        return this._updateCategories({uid: uid, id: id, categories: categories}).then(response => {
            return {
                success: true,
                payload: response
            }
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getByUID(uid) {
        return FB.database.collection(this.DATABASE_PRODUCTLISTINGS).doc(uid).get().then(doc => {
            if(doc.exists) {
                var data = doc.data()
                data.uid = doc.id
                return data
            } else {
                return {
                    success: false,
                    message: "Product does not exist"
                }
            }
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getByCategory(data) {
        
        var id = Number(data.id)
        var startAfter = data.startAfter 
        var pageSize = data.pageSize | 25

        return FB.database.collection(this.DATABASE_PRODUCTLISTINGS).where("categories", "array-contains", id).orderBy('id', 'asc').limit(pageSize).get().then(querySnapshot => {
            var products = []

            querySnapshot.forEach(doc => {
                var data = doc.data()
                data.uid = doc.id
                products.push(data)
            })

            return products
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getNextPageofCategory(data) {
        var id = Number(data.id)
        var startAfter = data.startAfter 
        var pageSize = data.pageSize | 25

        return FB.database.collection(this.DATABASE_PRODUCTLISTINGS).where("categories", "array-contains", id).orderBy("id").startAfter(startAfter).limit(pageSize).get().then(querySnapshot => {
            var products = []

            querySnapshot.forEach(doc => {
                var data = doc.data()
                data.uid = doc.id
                products.push(data)
            })

            return products
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getPreviousPageofCategory(data) {
        var id = Number(data.id)
        var endBefore = data.endBefore 
        var pageSize = data.pageSize | 25

        return FB.database.collection(this.DATABASE_PRODUCTLISTINGS).where("categories", "array-contains", id).orderBy("id").endBefore(endBefore).limit(pageSize).get().then(querySnapshot => {
            var products = []

            querySnapshot.forEach(doc => {
                var data = doc.data()
                data.uid = doc.id
                products.push(data)
            })

            return products
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })
    }

    getAllFromWooCommerce(data) {
        return this._getAllFromWooCommerce(data)
    }

    uploadToProducts(data) {
        if(Array.isArray(data)) {
            return this.uploadToDatabase(data, "products").then((resource) => {

                return {
                    success: true
                }
            }).catch((error) => {
                return {
                    success: false,
                    message: error.message
                }
            })
        } else {
            console.log("This isn't an array like it should be", data)
        }
    }

    uploadToListings(data) {

    }

    uploadToDatabase(products, resource) {
        var batches = []

        //Split up into 500 product chunks (500 is maximum operations per batch)
        var size = 100; 
        var chunks = [];
        for (var i = 0; i < products.length; i += size) {
            chunks.push(products.slice(i,i+size));
        }

        for(var i = 0; i < chunks.length; i++) {
            //Each chunk
            var chunk = chunks[i]
            var batch = FB.database.batch()

            for(var j = 0; j < chunk.length; j++) {
                //Each item in the current chunk
                var product = chunk[j]
                var docRef = FB.database.collection(this.DATABASE_PRODUCTLISTINGS).doc()
                batch.set(docRef, product)
            }

            batches.push(batch.commit())
        }

        return Promise.all(batches)
    }
}

const Products = new WMProducts()
export default Products