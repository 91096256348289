import ProductListingCategories from "@/api/ProductListingCategories"

const state = () => ({
    productCategory: null,
})

// getters
const getters = {
    getProductCategory(state) {
        return state.productCategory
    },
}

// actions
const actions = {
     setProductCategory({commit}, categoryID) {

        if(!categoryID) {
            console.log("Didn't get a product category")
            commit('setProductCategory', null)
        } else {
            console.log("Getting product category " + categoryID)
            ProductListingCategories.getProductCategory(categoryID).then((response) => {
                if(Array.isArray(response) && response[0] && response[0].id == categoryID) {
                    response = response[0]
                } else {
                    console.log("NO RESULTS")
                }
    
                console.log("CATEGROY", response)
    
                commit('setProductCategory', response)
            })
        }

   
    },
}

// mutations
const mutations = {
    setProductCategory(state, payload) {
        state.productCategory = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}