<template>
  <div id="appContainer">
    <TopNav id="topNav" v-if="isUserAuth"></TopNav>
    <div id="mainView" class="columns is-gapless">
      <div class="column is-narrow">
        <SideNav v-if="isUserAuth"></SideNav>
      </div>
      <div class="column page">
        <router-view class="currentPage" :key="$route.fullPath" v-slot="{Component}">
          <transition name="fade" mode="out-in">
            <component :is="Component" />

          </transition>
        </router-view>
      </div>
    </div>
    <ToastContainer></ToastContainer>
  </div>
</template>

<script>
import SideNav from "@/components/global/SideNav"
import TopNav from "@/components/global/TopNav"
import SignIn from "@/views/SignIn"
import $ from "jquery"
import {mapGetters} from "vuex"
import ToastContainer from "@/components/global/toast/ToastContainer"

export default {
  components: {
    SideNav,
    TopNav,
    SignIn,
    ToastContainer
  },
  methods: {
    updateState() {
      this.resize()
    },
    resize() {
      var winWidth = document.documentElement.clientWidth
      var winHeight = document.documentElement.clientHeight

      $("#mainView").height(winHeight - $("#topNav").height())
    },

    init() {
    }

    ,createToast() {
      this.$toast.create(Math.random())
    }
  },
  watch: {
    "isUserAuth": function() {
      console.log("IS USER AUTH? " + this.isUserAuth)
    }
  },
  computed: {
    ...mapGetters({isUserAuth: "User/isUserAuth"})
  },
  mounted: function() {
    
  
  }
}

</script>


<style lang="scss">
  @import '~bulma';
  @import "~bulma-o-steps";
  
  .has-shadow {
    -webkit-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.32);
    box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.32);
    border: 1px solid #dedede;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }


  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
  }

  body {
    width: 100vw;
    height: 100vh;
  
  }

  * {
    box-sizing: border-box;
  }

  .page {
    overflow-y: auto;
  }

  .currentPage {
    padding: 50px;
  }

  #appContainer {
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 55px 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "Top-Nav"
        "Main-View"; 
      width: 100%; 
      height: 100vh; 
  }

  #topNav {
    grid-area: Top-Nav;
  }

  #mainView {
    grid-area: Main-View;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
