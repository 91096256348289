import Database from "./Database"

class WMCustomerInquiries {
    getAll() {
        return Database.getAll("customer_inquiries").then((response) => {
            return response
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }
}

const CustomerInquiries = new WMCustomerInquiries()
export default CustomerInquiries