import Database from "./Database"
import FB from "./FB"

class WMUsers {

    initialize() {
        this._createUser = FB.getServerFunction("users-create")
        this._updatePassword = FB.getServerFunction("users-updatePassword")

    }

    getAll() {
        return Database.getAll("users").then((response) => {
            return response
        });
    }

    getOne(uid) {
        return Database.getOne("users", uid)
    }

    async getNameByUID(uid) {
        var user = await this.getOne(uid)
        return user.first_name + " " + user.last_name
    }

    createUser(data) {
        return this._createUser(data)
    }

    updatePassword(uid, password) {
        if(!uid || !password) {
            console.log("Unable to update password: Missing UID or Password")
            return false
        }
        return this._updatePassword({
            uid: uid,
            password: password
        }).then(response => {
            return true
        }).catch(error => {
            console.log("Error updating user password", error)
            return false
        })
    }

    isPasswordValid(password) {
        var pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
        return pattern.test(password)
    }

    getRandomPassword(letters, numbers, either) {
        var chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", // letters
        "0123456789", // numbers
        "!@#$%^&*" // either
        ];
    
        return [letters, numbers, either].map(function(len, i) {
            return Array(len).fill(chars[i]).map(function(x) {
            return x[Math.floor(Math.random() * x.length)];
            }).join('');
        }).concat().join('').split('').sort(function(){
            return 0.5-Math.random();
        }).join('')
    }

}

const Users = new WMUsers()
export default Users