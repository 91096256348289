import FB from "./FB"

class WMWebhooks {

    initialize() {
        this._getWebhooks = FB.getServerFunction('webhooks-manage-getHooks')
        this._resetWebhooks = FB.getServerFunction('webhooks-manage-resetWebhooks')
    }

    resetWebhooks(baseURL) {
        if(baseURL) {
            console.log("Sending reset to server", baseURL)
            return this._resetWebhooks({baseURL: baseURL}).then(response => {
                return response.data
            }).catch((error) => {
                return {
                    success: false,
                    message: error.message
                }
            })
        } else {
            console.warn("No Base URL provided to Webhook reset!")
            return {
                success: false,
                message: "No Base URL provided"
            }
        }

    }

    getAll() {
        return this._getWebhooks().then(response => {
            return response.data
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }

}

const Webhooks = new WMWebhooks()
export default Webhooks