import ProductLists from "@/api/ProductLists"

const state = () => ({
    lists: null,
})

// getters
const getters = {
    getLists(state) {
        return state.lists
    },
    getList: (state) => (uid) => {
        if(state.lists) {
            console.log("UID IS " + uid)
            return state.lists.find(list => list.uid == uid)
        }

        return "NOOOO"
    }
}

// actions
const actions = {
    setLists({commit}) {
        return ProductLists.getAll().then((response) => {
            commit("setLists", response)
        }).catch((error) => {
            console.log("Error getting product lists", error)
        })
    },
    addList({commit}, data) {
        commit("addList", data)
    },
    addToList({commit}, data) {
        var {uid, products} = data
        ProductLists.addToList(uid, products).then((response) => {
            commit("addToList", {uid: uid, payload: data})
        }).catch(error => {
            console.log("error adding to list", error)
        })
    }
}

// mutations
const mutations = {
    setLists(state, payload) {
        state.lists = payload;
    },
    addList(state, payload) {
        state.lists.push(payload)
    },
    addToList(state, payload) {
        var {uid, products} = payload
        var lists = state.lists
        for(var i = 0; i < lists; i++) {
            var list = lists[i]

            if(list.uid == uid) {
                state.lists[i].concat(products)
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}