import { createStore } from 'vuex'
import User from './modules/User'
import Users from './modules/Users'
import ProductListingCategories from './modules/ProductListingCategories'
import ProductCategory from './modules/ProductCategory'
import ProductListings from './modules/ProductListings'
import Webhooks from './modules/Webhooks'
import WebsiteOrders from './modules/WebsiteOrders'
import ProductUpdates from './modules/ProductUpdates'
import ProductLists from './modules/ProductLists'
import CustomerInquiries from './modules/CustomerInquiries'
import MailingList from './modules/MailingList'
import Toasts from './modules/Toasts'
import Products from './modules/Products'
import ProductSuppliers from './modules/ProductSuppliers'
import ProductCategories from './modules/ProductCategories'
import BugReports from "./modules/Bugs"
import PricingProfiles from './modules/PricingProfiles'
import Promotions from './modules/Promotions'

export default createStore({
  modules: {
    User,
    Users,
    ProductListingCategories,
    ProductCategory,
    ProductListings,
    Webhooks,
    WebsiteOrders,
    ProductUpdates,
    ProductLists,
    CustomerInquiries,
    MailingList,
    Toasts,
    Products,
    ProductSuppliers,
    ProductCategories,
    BugReports,
    PricingProfiles,
    Promotions
  },
})
