<template>
  <div class="SideNavigationBar has-background-light">
    <aside class="menu">
      <ul class="menu-list">
        <li ><router-link :class="currentRoute == 'Dashboard'? 'is-active' : ''" to="/dashboard">Dashboard</router-link></li>
      </ul>
      <p class="menu-label" v-if="hasPermission('products')">
        Products
      </p>
      <ul class="menu-list">
        <li v-if="hasPermission('products')">
          <router-link :class="currentRoute == 'All Products'? 'is-active' : ''" to="/dashboard/products">View All</router-link>
        </li>
      </ul>
      <p class="menu-label" v-if="hasPermission('quotes')">
        Quotes
      </p>
      <ul class="menu-list" v-if="hasPermission('quotes')">
        <li v-if="hasPermission('quotes-edit')">
          <router-link :class="currentRoute == 'New Quote'? 'is-active' : ''" to="/dashboard/quotes/new">Create a Quote</router-link>
        </li>
        <li v-if="hasPermission('quotes-view')"><a>View Quotes</a></li>
      </ul>
      
      <p class="menu-label" v-if="hasPermission('orders', 'customerinquiries')">
        Website
      </p>
      <ul class="menu-list">
        <li v-if="hasPermission('products')">
          <a @click="toggleSubMenu" data-target="categorySubMenu" class="has-subitems">Categories</a>
          <ul :class="'submenu ' + (categoryMenuIsOpen? 'open' : '')">
            <li v-for="category in productCategories" :key="category.id">
              <router-link :class="($route.params && $route.params.id && $route.params.id == category.id)? 'is-active' : ''" :to="'/dashboard/listings/category/' + category.id" v-html="category.name"></router-link>
            </li>
          </ul>
        </li>
        <li v-if="hasPermission('orders')">
          <router-link :class="currentRoute == 'Website Orders'? 'is-active' : ''" to="/dashboard/orders/website">Orders</router-link>
        </li>
        <li v-if="hasPermission('customerinquiries')">
          <router-link :class="currentRoute == 'Customer Inquiries'? 'is-active' : ''" to="/dashboard/inquiries">Customer Inquiries</router-link>
        </li>
        <li v-if="hasPermission('promotions')">
          <router-link :class="currentRoute == 'Promotions'? 'is-active' : ''" to="/dashboard/listings/promotions">Promotions</router-link>
        </li>
      </ul>

      <p class="menu-label" v-if="hasPermission('mailinglist', 'users')">
        Manage
      </p>
      <ul class="menu-list">
        <li v-if="hasPermission('mailinglist')">
          <router-link :class="currentRoute == 'Mailing List'? 'is-active' : ''" to="/dashboard/mailing">Mailing List</router-link>
        </li>
        <li v-if="hasPermission('mailinglist')">
          <router-link :class="currentRoute == 'Pricing Profiles'? 'is-active' : ''" to="/dashboard/pricing">Pricing Profiles</router-link>
        </li>
        <li v-if="hasPermission('users')">
          <router-link :class="currentRoute == 'User Management'? 'is-active' : ''" to="/dashboard/users">Users</router-link>
        </li>


      </ul>
      <p class="menu-label" v-if="hasPermission('*')">
        Administration
      </p>
      <ul class="menu-list">
        <li v-if="hasPermission('*')">
          <router-link :class="currentRoute == 'Bug Reports'? 'is-active' : ''" to="/dashboard/bugs">Bug Reports</router-link>
        </li>
        <li v-if="hasPermission('databases')">
          <router-link :class="currentRoute == 'Database Management'? 'is-active' : ''" to="/dashboard/databases">Databases</router-link>
        </li>
        <li v-if="hasPermission('webhooks')">
          <router-link :class="currentRoute == 'Webhooks'? 'is-active' : ''" to="/dashboard/databases/webhooks">Webhooks</router-link>
        </li>
      </ul>

    </aside>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Permissions from '@/api/Permissions'

export default {
  name: 'SideNavigationBar',
  data() {
    return {
      categoryMenuIsOpen: false,
    }
  },
  computed: {
    currentRoute: function() {
      return this.$route.name
    },
    productCategories: function() {
      if(!this.getProductCategories) return []

      var categories = []

      this.getProductCategories.forEach(category => {
        if(category.parent == 0) {
          categories.push(category)
        }
      })

      return categories.sort((a,b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
    },
    ...mapGetters({
        productCategories: 'ProductListingCategories/getProductCategories',
        user: 'User/getProfile'
      })
  },
  methods: {
    hasPermission(...permissions) {
      return Permissions.hasPermission(permissions)
    },
    toggleSubMenu(e) {
      console.log("ROUTE", this.$route)
      this.categoryMenuIsOpen = !this.categoryMenuIsOpen
    }
  },
  mounted: function() {
    this.$store.dispatch("ProductListingCategories/setProductCategories")
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .SideNavigationBar {
    height: 100%;
    overflow-y:auto;
    padding: 15px;
    padding-top: 25px;
    min-width: 250px;
    border-right: 1px solid #ddd;
    max-height: calc(100vh - 55px);
  }

  .submenu {
    display: none;
  }

  .submenu.open {
    display: block;

  }
</style>
