import MailingList from "@/api/MailingList"
import algoliasearch from 'algoliasearch/lite';

const client = algoliasearch('1XZQWYJKQ7', '3c3fa1681627fd3f881a256e394cbdae');
const index = client.initIndex('mailing_list');

const state = () => ({
    contacts: null,
})

// getters
const getters = {
    getContacts(state) {
        return state.contacts
    },
}

// actions
const actions = {
    setContacts({commit}) {
        MailingList.getContacts().then(response => {
            commit("setContacts", response)
        })
    },
    addContact({commit}, contact) {
        MailingList.addContact(contact).then((response) => {
            commit("addContact", contact)
        }).catch((error) => {
            console.log("Error adding contact to mailing list", error)
        })
    },
    deleteContact({commit}, contact) {
        MailingList.deleteContact(contact).then((response) => {
            commit("deleteContact", contact)
        }).catch(error => {
            console.log("Error deleting contact", error)
        })
    },
    async search({commit}, query) {
        const hits = await index.search(query, {
            hitsPerPage: 10
        })
        console.log(hits)
        commit("setContacts", hits.hits)
    }
}

// mutations
const mutations = {
    setContacts(state, payload) {
        state.contacts = payload;
    },
    addContact(state, payload) {
        state.contacts.push(payload)
    },
    deleteContact(state, payload) {
        var index = state.contacts.indexOf(payload)
        state.contacts.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}