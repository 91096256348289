import Database from "./Database"

class WMBugReports {

    getAll() {
        return Database.getAll("bug_reports")
    }

}

const BugReports = new WMBugReports()
export default BugReports