import Database from "./Database"

class WMPricingProfiles {

    getAll() {
        return Database.getAll("pricing_profiles")
    }

    create(profile) {
        return Database.addOne("pricing_profiles", profile).then(response => {
            profile.uid = response.id
            return profile
        })
    }

    getUserPricingProfile(userUID) {
        return Database.getOneWhere("pricing_profiles", "users", "array-contains", userUID)
    }

}

const PricingProfiles = new WMPricingProfiles()
export default PricingProfiles