import Database from "./Database"
import firebase from "firebase"

class WMUser {
    signIn(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password)
    }

    signOut() {
        return firebase.auth().signOut()
    }

    create(email, password) {

    }

    getProfile(uid) {
        return Database.getOne("users", uid).then((response) => {
            return response
        })
    }

    getCurrentAuthUser() {
        return firebase.auth().currentUser
    }
}

const User = new WMUser()
export default User