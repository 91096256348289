import ProductListingCategories from "@/api/ProductListingCategories"

const state = () => ({
    productCategories: null,
})

// getters
const getters = {
    getProductCategories(state) {
        return state.productCategories
    },
}

// actions
const actions = {
     setProductCategories({commit}) {
        ProductListingCategories.getProductCategories().then((response) => {
            commit('setProductCategories', response)
        })
    },
}

// mutations
const mutations = {
    setProductCategories(state, payload) {
        state.productCategories = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}