import Database from "./Database"


class WMProducts {
    get(data) {
        return Database.getAll("products")
    }

    getOne(uid) {
        console.log("Getting " + uid)
        return Database.getOne("products", uid).then(response => {
            console.log(response)
            return response
        })
    }

    delete(uid) {
        return Database.deleteOne("products", uid).then(response => {
            return true
        }).catch((error) => {
            console.log("Error deleting product", error)
        })
    }

    update(data) {
        return Database.updateOne("products", data.uid, data).then(response => {
            return data
        }).catch(error => {
            console.log("Error updating product", error)
        })
    }

    create(data) {
        return Database.addOne("products", data).then((response) => {
            data.uid = response.id
            console.log("Created product", data)
            return data
        }).catch(error => {
            console.log("Error creating product", error)
            return null
        })
    }
}

const Products = new WMProducts()
export default Products