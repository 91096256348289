import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'

import firebase from 'firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signin',
    name: 'Sign In',
    component: SignIn,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue')
  },
  {
    path: '/dashboard/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Search.vue')
  },
  {
    path: '/dashboard/bugs',
    name: 'Bug Reports',
    component: () => import(/* webpackChunkName: "administration" */ '../views/dashboard/bugs/BugReports.vue')
  },
  {
    path: '/dashboard/listings/category/:id',
    name: 'Product Category',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/Category')
  },
  {
    path: '/dashboard/listings/:uid',
    name: 'View Product Listing',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/Product')
  },
  {
    path: '/dashboard/listings/:uid/edit',
    name: 'Edit Product Listing',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/EditProduct')
  },
  {
    path: '/dashboard/listings/:uid/variations',
    name: 'Variation Builder',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/VariationBuilder')
  },
  {
    path: '/dashboard/listings/updates',
    name: 'Product Updates',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/ProductUpdates')
  },
  {
    path: '/dashboard/listings/lists',
    name: 'Product Lists',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/ProductLists')
  },
  {
    path: '/dashboard/listings/lists/:uid',
    name: 'Product List',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/productListings/ProductList')
  },
  {
    path: '/dashboard/listings/promotions',
    name: 'Promotions',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/promotions/Promotions')
  },
  {
    path: '/dashboard/listings/promotions/new',
    name: 'New Promotion',
    component: () => import(/* webpackChunkName: "productlistings" */ '../views/dashboard/promotions/NewPromotion')
  },
  {
    path: '/dashboard/products',
    name: 'All Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/dashboard/products/AllProducts')
  },
  {
    path: '/dashboard/products/create',
    name: 'Create Product',
    component: () => import(/* webpackChunkName: "products" */ '../views/dashboard/products/CreateProduct')
  },
  {
    path: '/dashboard/products/:uid',
    name: 'View Product',
    component: () => import(/* webpackChunkName: "products" */ '../views/dashboard/products/SingleProduct')
  },
  {
    path: '/dashboard/products/:uid/edit',
    name: 'Edit Product',
    component: () => import(/* webpackChunkName: "products" */ '../views/dashboard/products/EditProduct')
  },
  {
    path: '/dashboard/orders/',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/dashboard/orders/Orders')
  },
  {
    path: '/dashboard/orders/:id',
    name: 'Order',
    component: () => import(/* webpackChunkName: "orders" */ '../views/dashboard/orders/Order')
  },
  {
    path: '/dashboard/orders/website',
    name: 'Website Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/dashboard/websiteOrders/WebsiteOrders')
  },
  {
    path: '/dashboard/orders/website/:uid',
    name: 'Website Order',
    component: () => import(/* webpackChunkName: "orders" */ '../views/dashboard/websiteOrders/WebsiteOrder')
  },
  {
    path: '/dashboard/users',
    name: 'User Management',
    component: () => import(/* webpackChunkName: "users" */ '../views/dashboard/users/Users')
  },
  {
    path: '/dashboard/users/create',
    name: 'Create User',
    component: () => import(/* webpackChunkName: "users" */ '../views/dashboard/users/CreateUser')
  },
  {
    path: '/dashboard/users/:uid',
    name: 'Edit User',
    component: () => import(/* webpackChunkName: "users" */ '../views/dashboard/users/EditUser')
  },
  {
    
    path: '/dashboard/databases',
    name: 'Database Management',
    component: () => import(/* webpackChunkName: "databases" */ '../views/dashboard/databases/Databases')
  },
  {
    
    path: '/dashboard/databases/webhooks',
    name: 'Webhooks',
    component: () => import(/* webpackChunkName: "databases" */ '../views/dashboard/databases/Webhooks')
  },
  {
    
    path: '/dashboard/databases/rebuild/products',
    name: 'Rebuild Product',
    component: () => import(/* webpackChunkName: "databases" */ '../views/dashboard/databases/rebuild/Products')
  },
  {
    
    path: '/dashboard/pricing',
    name: 'Pricing Profiles',
    component: () => import(/* webpackChunkName: "administration" */ '../views/dashboard/pricing/PricingProfiles')
  },
  {
    path: '/dashboard/inquiries',
    name: 'Customer Inquiries',
    component: () => import(/* webpackChunkName: "administration" */ '../views/dashboard/customerInquiries/CustomerInquiries')
  },
  {
    path: '/dashboard/inquiries/:uid',
    name: 'Customer Inquiry',
    component: () => import(/* webpackChunkName: "administration" */ '../views/dashboard/customerInquiries/CustomerInquiry')
  },
  {
    path: '/dashboard/mailing',
    name: 'Mailing List',
    component: () => import(/* webpackChunkName: "administration" */ '../views/dashboard/mailing/MailingList')
  },
  {
    path: '/dashboard/quotes/new',
    name: 'New Quote',
    component: () => import(/* webpackChunkName: "quotes" */ '../views/dashboard/quotes/NewQuote')
  }
]

for(var i = 0; i < routes.length; i++) {
  var route = routes[i]

  if(route.name != "Sign In") {
    routes[i].meta = {
      requiresAuth: true
    }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  var user = firebase.auth().currentUser

  if(requiresAuth) {
    if(!user) {
      next("/signin")
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
