import ProductCategories from "@/api/ProductCategories"

const state = () => ({
    categories: [],
})

// getters
const getters = {
    getProductCategories(state) {
        return state.categories
    },
}

// actions
const actions = {
    setProductCategories({commit}) {
        return ProductCategories.getAll().then(response => {
            commit('setCategories', response)
            return response
        }).catch(error => {
            console.log("Error getting product categories", error)
        })
    }
}

// mutations
const mutations = {
    setCategories(state, payload) {
        state.categories = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}