<template>
  <div class="toastContainer">
    <transition-group name="list" tag="Toast">
      <Toast class="toast" :active="toast.active" :message="toast.message" v-for="(toast, i) in toasts" :key="i"></Toast>
    </transition-group>
  </div>
</template>

<script>
import Toast from "@/components/global/toast/Toast"
import {mapGetters} from 'vuex'

export default {
  name: 'Toast Container',
  components: {
    Toast
  },
  computed: {
    ...mapGetters({
      toasts: "Toasts/getToasts"
    })
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(130px);
  }

  .toast {
    width: 350px;
    margin-bottom: 25px;
  }

  .toastContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    pointer-events: none;
    position: fixed;
    top: 75px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999999999999999999;
    padding: 25px;
  }
</style>
