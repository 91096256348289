<template>
  <div class="signIn">
    <form v-on:submit.prevent="onSubmit" class="signInForm">
      <h1 class="title">Sign In</h1>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input class="input" type="email" v-model="email" required>
        </div>
      </div>

      <div class="field">
        <label class="label">Password</label>
        <div class="control">
          <input class="input" type="password" v-model="password" required>
        </div>
      </div>

      <button class="button is-fullwidth is-primary" :disabled="isSubmitDisabled">Sign In</button>
    </form>
  </div>
</template>

<script>
import User from "@/api/User"
import {mapGetters} from "vuex"

export default {
  name: 'Sign In',
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    onSubmit(e) {
      this.$emit("stateUpdate")
      User.signIn(this.email, this.password).then(this.redirect).catch(this.handleError)
    },
    handleError(err) {
      var message = "Invalid username or password"

      this.$toast.create(message)
    },
    redirect(response) {
      console.log(response)
      this.$router.push("/dashboard")
    }
  },
  computed: {
    isSubmitDisabled: function() {
      return this.email.length <= 0 || this.password.length <= 0
    },
    ...mapGetters({isUserAuth: "User/isUserAuth"})

  },
  watch: {
    "isUserAuth": function() {
      if(User.getCurrentAuthUser() || this.isUserAuth) {
        this.$router.push("/dashboard")
      }
    }
  },
  mounted: function() {
    if(User.getCurrentAuthUser() || this.isUserAuth) {
      this.$router.push("/dashboard")
    }
  }
}
</script>
<style scoped>
  .signIn {
    
    width: 100%;
    height: 95vh;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .signInForm {
    min-width: 12.5vw;
  }
</style>
