import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './firebaseConfig'
import firebase from 'firebase'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import toast from "@/api/Toast" 

var loaded = false
function initApp() {
    if(!loaded) {
        createApp(App).use(store).use(toast).use(router).mount('#app')
        loaded = true
    }
}


firebase.auth().onAuthStateChanged(async _user => {
    if(_user) {
        store.dispatch("User/setUser", _user)
        store.dispatch("PricingProfiles/setProfile", _user.uid)
    } else {
        store.dispatch("User/setUser", null)
        store.dispatch("PricingProfiles/setProfile", null)
    }

    initApp()
});

