import Database from "./Database"
import FB from "./FB"

class WMProductUpdates {
    getAll() {
        return Database.getAll("product_updates")
    }

    submitUpdate(sku, description) {
        var submittedBy = FB.firebase.auth().currentUser.uid

        return FB.database.collection("product_updates").add({
            sku: sku,
            description: description,
            submitted_by: submittedBy,
            status: "pending"
        }).then((response) => {
            return response
        }).catch((error) => {
            return {
                success: false,
                message: error.message
            }
        })        
    }

    updateStatus(uid, status) {
        return FB.database.collection("product_updates").doc(uid).update({
            status: status
        }).then(response => {
            return response
        }).catch(error => {
            return {
                success: false,
                message: error.message
            }
        })
    }
}

const ProductUpdates = new WMProductUpdates()
export default ProductUpdates