<template>
  <div class="home">
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {

  },
  mounted: function() {
    this.$router.push("/dashboard")
  }
}
</script>
