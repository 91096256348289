import User from "@/api/User"
import Permissions from "@/api/Permissions"

const state = () => ({
    user: null,
    profile: null
})

// getters
const getters = {
    getUser(state) {
        return state.user;
    },
    getProfile(state) {
        return state.profile
    },
    isUserAuth(state) {
        return !!state.user;
    },
}

// actions
const actions = {
    setUser({commit}, user) {
        if(user) {
            User.getProfile(user.uid).then((response) => {
                Permissions.setProfile(response)
                commit('setUser', user)
                commit('setProfile', response)
            })
        }
    },
    signOut({commit}) {
        User.signOut().then(()=> {
            commit("setUser", null)
            this.$router.push("/signin")
        })
    }
}

// mutations
const mutations = {
    setUser(state, payload) {
        state.user = payload;
    },
    setProfile(state, payload) {
        state.profile = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}