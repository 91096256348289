import CustomerInquiries from "@/api/CustomerInquiries"

const state = () => ({
    inquiries: null
})

// getters
const getters = {
    getInquiries(state) {
        if(!state.inquiries) return []
        return state.inquiries.sort((a, b) => b.date.getTime() - a.date.getTime());
    },
    getInquiryByUID: (state) => (uid) => {
        if(state.inquiries) {
            console.log("FINDING " + uid)

            return state.inquiries.find(inquiry => inquiry.uid == uid)
            console.log("None matching")
        } else {
            console.log("No inquiries")
        }
    }
}

// actions
const actions = {
    setInquiries({commit}) {


        return CustomerInquiries.getAll().then(response => {
            console.log(response)
            for(var i = 0; i < response.length; i++) {
                var entry = response[i]

                if(entry.date) {
                    entry.date = new Date(entry.date.seconds * 1000)
                }

                
                entry.name = entry.firstname + " " + entry.lastname
                
            }
            commit('setInquiries', response)
        })
    }
}

// mutations
const mutations = {
    setInquiries(state, payload) {
        state.inquiries = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}