
class WMToasts {
    constructor() {
        this.ids = []
    }

    generateID() {
        var id = Math.random()
        if(this.ids.includes(id)) {
            id = id + 1
        }

        return id
    }

    create(message, title, duration = 5000) {
        var id = this.generateID()

        this.$store.dispatch("Toasts/addToast", {message: message, id: id})

        setTimeout(() => {this.removeToast(id)}, duration)
    }   

    removeToast(id) {
        this.$store.dispatch("Toasts/removeToast", id)
    }

    useStore(store) {
        this.$store = store
    }
}

const Toasts = new WMToasts()

export default {
    install: (app, options) => {
        app.mixin({
            created() {
                Toasts.useStore(this.$store)
                this.$toast = Toasts
            }
        })   
    }
}