<template>
  <div class="areYouSure">
    <div :class="'modal ' + (open? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Report a Problem</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Describe The Issue</label>
            <textarea v-model="message" class="textarea"></textarea>
            <p class="help">The more information you can provide, the better</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :disabled="message.length <= 0" @click="reportProblem">Submit</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Bugs from "@/api/Bugs"
import User from "@/api/User"

export default {
  name: 'Report a Problem Modal',
  props: {
    open: Boolean,
  },
  data() {
    return {
      message: ""
    }
  },
  methods: {
    reportProblem() {
      Bugs.report({
        message: this.message,
        submitted_by: User.getCurrentAuthUser().uid,
        resolved: false
      }).then(this.onSubmit).catch(this.close)
    },
    onSubmit() {
      this.$toast.create("Thanks! Your report has been submitted")
      this.close()
    },
    close() {
      this.$emit("close")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
