import FB from "./FB"

class WMDatabase {
    constructor() {
        this.OPERATOR_EQUALS = "=="
        
        //  Table Names
        this.RESOURCE_USERS = "users"
    }

    addOne(resource, data) {
        return FB.database.collection(resource).add(data)
    }

    getAll(resource, options) {

        var limit = 100

        if(options && options.limit) {
            limit = options.limit
        }

        return FB.database.collection(resource).limit(limit).get().then((querySnapshot) => {
            var results = []
            querySnapshot.forEach(doc => {
                var data = doc.data()
                data.uid = doc.id
                results.push(data)
            })

            return results
        })
    }

    getOne(resource, documentID) {
        return FB.database.collection(resource).doc(documentID).get().then((doc) => {
            if(doc.exists) {
                var data = doc.data()
                data.uid = doc.id
                return data
            } else {
                return false
            }
        })
    }

    getAllWhere(resource, key, operator, value) {
        return FB.database.collection(resource).where(key, operator, value).get().then((response) => {
            var results = []

            response.forEach((doc) => {
                var data = doc.data()
                data.uid = doc.id
                results.push(data)
            })

            return results
       }).catch((error) => {
           return {
               success: false,
               message: error.message
           }
       })
    }

    getOneWhere(resource, key, operator, value) {
       return FB.database.collection(resource).where(key, operator, value).get().then((response) => {
            var results = []

            response.forEach((doc) => {
                var data = doc.data()
                data.uid = doc.id
                results.push(data)
            })

            if(results && results[0]) {
                return results[0]
            } else {
                return undefined
            }
       }).catch((error) => {
           return {
               success: false,
               message: error.message
           }
       })
    }

    deleteOne(resource, uid) {
        return FB.database.collection(resource).doc(uid).delete()
    }

    updateOne(resource, uid, data) {
        return FB.database.collection(resource).doc(uid).update(data)
    }
}

const Database = new WMDatabase()
export default Database